var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { Dependencies } from "~/core/decorator";
import { SortService } from "~/utils/sort.service";
import { PageService } from "~/utils/page.service";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { WarningService } from "~/services/business-service/warning.service";
var workspaceModule = namespace("workspace");
var WarningDetails = /** @class */ (function (_super) {
    __extends(WarningDetails, _super);
    function WarningDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.loading = {
            state: false
        };
        _this.dialog = { repeat: false };
        _this.approvalModel = {
            id: '',
            caseId: '',
            content: ''
        };
        _this.approvalRules = {
            content: [
                { required: true, message: "请输入警告内容", trigger: "blur" }
            ]
        };
        _this.currentId = '';
        return _this;
    }
    WarningDetails.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.warningService.findAllWarning({ caseId: this.caseId }, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    WarningDetails.prototype.cancel = function () {
        this.dialog.repeat = false;
        var form = this.$refs['approval-form'];
        form.resetFields();
    };
    WarningDetails.prototype.approvalCommit = function () {
        var _this = this;
        var form = this.$refs['approval-form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.warningService.modifyWarning(_this.approvalModel, _this.loading).subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.cancel();
                _this.refreshData();
            }, function (err) { return _this.$message.error(err.msg); });
        });
    };
    WarningDetails.prototype.repeatContent = function (row) {
        this.dialog.repeat = true;
        this.approvalModel.content = row.content;
        this.approvalModel.id = row.id;
        this.approvalModel.caseId = row.caseId;
    };
    WarningDetails.prototype.deleteContent = function (row) {
        var _this = this;
        this.loading.state = true;
        this.warningService.deleteWarning(row.id, this.loading).subscribe(function (data) {
            _this.$message.success("操作成功");
            _this.refreshData();
        }, function (err) { return _this.$message.error(err.msg); });
    };
    __decorate([
        Dependencies(WarningService)
    ], WarningDetails.prototype, "warningService", void 0);
    __decorate([
        Dependencies(PageService)
    ], WarningDetails.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], WarningDetails.prototype, "sortService", void 0);
    __decorate([
        Prop()
    ], WarningDetails.prototype, "caseId", void 0);
    WarningDetails = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm
            }
        })
    ], WarningDetails);
    return WarningDetails;
}(Vue));
export default WarningDetails;
