var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { State, Getter } from "vuex-class";
import AddNote from "~/components/case-adjustment/add-note.vue";
import AddWarning from "~/components/case-adjustment/add-warning.vue";
import WaringDetails from "~/components/case-adjustment/warning-details.vue";
import RefreshRetirementTime from "~/components/case-adjustment/refresh-retirement-time.vue";
import RetireCase from "~/components/case-manage/case-all-manage/retire-case.vue";
import ImportBatchRetireCase from "~/components/case-manage/case-all-manage/import-batch-retire-case.vue";
import DeleteFollowRecord from "~/components/case-manage/case-adjustment/delete-follow-record.vue";
import AdjustmentCaseAllot from "~/components/case-info/adjustment-case-allot.vue";
import AdjustmentCaseBatchAllot from "~/components/case-info/adjustment-case-batch-allot.vue";
import FollowRecordExport from "~/components/case-manage/case-all-manage/follow-record-export.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import caseExport from "~/components/case-manage/case-all-manage/case-export.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { MENU_CHANNEL } from "~/config/enum.business";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import NumberRange from "~/components/common/number-range.vue";
var CaseAdjustment = /** @class */ (function (_super) {
    __extends(CaseAdjustment, _super);
    function CaseAdjustment() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.channel = MENU_CHANNEL.CASE_ADJUSTMENT;
        _this.caseDataStatus = "IN_POOL";
        _this.exportData = {};
        _this.selectionList = [];
        _this.searchModel = {
            personalName: "",
            caseDataStatus: _this.caseDataStatus,
            certificateNo: "",
            principalId: "",
            batchNumber: "",
            leaveFlag: "",
            deptCode: "",
            comment: "",
            retireCaseDays: "",
            account: "",
            deptIds: [],
            city: "",
            delegationDate: [],
            endCaseDate: [],
            handsNumber: "",
            phone: "",
            cardNo: "",
            address: "",
            employerName: "",
            delegationMonth: "",
            cardNoType: "",
            unhealthyFlag: "",
            receiveName: "",
            batchNo: "",
            extensionInfo: "",
            extensionAgent: "",
            payStatus: "",
            leftAmt: {
                min: "",
                max: "",
            },
            overduePeriods: {
                min: "",
                max: "",
            },
            overdueDays: {
                min: "",
                max: "",
            },
            currentCollectorName: "",
            followTime: "",
            organization: "",
        };
        _this.dataSet = [];
        _this.money = "";
        _this.dialog = {
            addnote: false,
            addwaring: false,
            waringDetails: false,
            adjustmentManualDivision: false,
            batchDivision: false,
            followRecordExport: false,
            caseExport: false,
            retirement: false,
            retirecase: false,
            batchRetireCase: false,
            deleteFollowCase: false,
        };
        _this.currentCase = {};
        _this.loading = { state: false };
        _this.collectionStatus = [];
        _this.collectorDate = [];
        _this.idsList = []; //退案时传入退案页面的id集合
        _this.rules = {
            certificateNo: [{ min: 6, message: "请输入至少6位的身份证号", trigger: "blur" }],
            phone: [{ min: 4, message: "请输入至少4位的手机号", trigger: "blur" }],
            cardNo: [{ min: 6, message: "请输入至少6位的卡号", trigger: "blur" }],
            account: [{ min: 6, message: "请输入至少6位的账号", trigger: "blur" }],
        };
        return _this;
    }
    CaseAdjustment.prototype.mounted = function () {
        this.collectorDate = this.collectorList.userNameList.map(function (x) {
            return { value: x.name, userName: x.userName };
        });
        this.refreshData();
    };
    CaseAdjustment.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.collectorDate.filter(this.createFilter(queryString)) : this.collectorDate;
        cb(results);
    };
    CaseAdjustment.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
    };
    CaseAdjustment.prototype.stopCase = function () {
        var _this = this;
        if (!this.selectionList.length) {
            this.$message("请选择要停调的案件！");
            return;
        }
        var idList = this.selectionList.map(function (v) { return v.id; });
        this.$confirm("确定停调已选择的案件吗？", "提示", { type: "warning" }).then(function () {
            _this.collectionCaseService.stopCollectionCase({ idList: idList }).subscribe(function (data) {
                _this.$message.success("操作成功！");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    CaseAdjustment.prototype.returnCase = function () {
        if (!this.selectionList.length) {
            this.$message("请选择要退案的案件！");
            return;
        }
        this.idsList = this.selectionList.map(function (v) { return v.id; });
        this.dialog.retirecase = true;
    };
    CaseAdjustment.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        if (this.searchModel.userName) {
            var usernameNew = this.collectorList.userNameList.find(function (x) { return x.name === _this.searchModel.userName; }).userName;
            this.searchModel = Object.assign(this.searchModel, { userName: usernameNew });
        }
        this.collectionCaseService.queryAdjustCase(this.searchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
        this.amount();
    };
    CaseAdjustment.prototype.amount = function () {
        var _this = this;
        this.collectionCaseService.queryAdjustCaseAmt(this.searchModel).subscribe(function (data) {
            _this.money = data;
        });
    };
    CaseAdjustment.prototype.addNoteMethod = function (scope) {
        this.currentCase = scope.row;
        this.dialog.addnote = true;
    };
    CaseAdjustment.prototype.addWaring = function (scope) {
        this.currentCase = scope.row;
        this.dialog.addwaring = true;
    };
    CaseAdjustment.prototype.refreshTime = function (scope) {
        this.currentCase = scope.row;
        this.dialog.retirement = true;
    };
    /**
     * 案件导出
     */
    CaseAdjustment.prototype.caseExport = function () {
        var exportModel = {};
        if (this.selectionList.length > 0)
            exportModel.caseIds = this.selectionList.map(function (v) { return v.id; });
        exportModel = Object.assign(exportModel, this.searchModel);
        this.exportData = exportModel;
        this.dialog.caseExport = true;
    };
    /**
     * 查看警告详情
     */
    CaseAdjustment.prototype.waringDetails = function (scope) {
        this.currentCase = scope.row;
        //
        this.dialog.waringDetails = true;
    };
    CaseAdjustment.prototype.onManualSeparate = function () {
        if (this.selectionList.length === 0) {
            this.$message.info("请选择要分配的案件");
            return;
        }
        this.dialog.adjustmentManualDivision = true;
    };
    CaseAdjustment.prototype.onAdjustmentManualDivisionOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["adjustment-case-allot"];
            form.refreshData();
        });
    };
    CaseAdjustment.prototype.getConfigByPrin = function () {
        var _this = this;
        if (!this.$route.params["principalId"])
            return;
        this.loading.state = true;
        this.custConfigService.getConfigByPrin(this.$route.params["principalId"], this.loading).subscribe(function (data) {
            _this.collectionStatus = data.collStates;
        });
    };
    /**
     * 批次分配
     */
    CaseAdjustment.prototype.onBatchDivision = function () {
        this.dialog.batchDivision = true;
    };
    CaseAdjustment.prototype.onBatchDivisionOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["adjustment-case-batch-allot"];
            form.refreshData();
        });
    };
    /**
     * 调记导出
     */
    CaseAdjustment.prototype.followRecordExport = function () {
        var exportModel = {};
        if (this.selectionList.length > 0)
            exportModel.caseIds = this.selectionList.map(function (v) { return v.id; });
        exportModel = Object.assign(exportModel, this.searchModel);
        this.exportData = exportModel;
        this.dialog.followRecordExport = true;
    };
    CaseAdjustment.prototype.retirementTime = function () {
        this.currentCase = null;
        this.dialog.retirement = true;
    };
    CaseAdjustment.prototype.importBatchRetireCase = function () {
        this.currentCase = null;
        this.dialog.batchRetireCase = true;
    };
    CaseAdjustment.prototype.deleteFollowCase = function () {
        this.currentCase = null;
        this.dialog.deleteFollowCase = true;
    };
    /**
     * 机构改变
     */
    CaseAdjustment.prototype.orgChange = function (valueArray) {
        if (this.searchModel.deptIds.length < 1)
            return;
        this.searchModel.deptCode = valueArray[valueArray.length - 1];
    };
    CaseAdjustment.prototype.handleResetForm = function () {
        this.searchModel.deptCode = "";
    };
    CaseAdjustment.prototype.refreshDataTwo = function () { };
    __decorate([
        Dependencies(PageService)
    ], CaseAdjustment.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CaseAdjustment.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CollectionCaseService)
    ], CaseAdjustment.prototype, "collectionCaseService", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], CaseAdjustment.prototype, "custConfigService", void 0);
    __decorate([
        State
    ], CaseAdjustment.prototype, "principalList", void 0);
    __decorate([
        State
    ], CaseAdjustment.prototype, "collectorList", void 0);
    __decorate([
        Getter
    ], CaseAdjustment.prototype, "departmentData", void 0);
    CaseAdjustment = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                AddNote: AddNote,
                AddWarning: AddWarning,
                NumberRange: NumberRange,
                WaringDetails: WaringDetails,
                AdjustmentCaseAllot: AdjustmentCaseAllot,
                AdjustmentCaseBatchAllot: AdjustmentCaseBatchAllot,
                FollowRecordExport: FollowRecordExport,
                caseExport: caseExport,
                PrincipalBatch: PrincipalBatch,
                RefreshRetirementTime: RefreshRetirementTime,
                RetireCase: RetireCase,
                ImportBatchRetireCase: ImportBatchRetireCase,
                DeleteFollowRecord: DeleteFollowRecord,
            },
        })
    ], CaseAdjustment);
    return CaseAdjustment;
}(Vue));
export default CaseAdjustment;
